import React from 'react'
import { Branding } from '../../components/index'

const BrandingPage = () => {
  return (
    <main>
      <Branding />
    </main>
  )
}

export default BrandingPage
